import React from 'react';
import { Helmet } from 'react-helmet';
import HeroImg from '../images/hero.svg';

export default function Index() {
  return (
    <main>
      <Helmet title="ルンルンバナナ バナナジュース専門店 RunRun Banana" />
      <div className="container mx-auto">
        <section className="flex flex-col sm:flex-row mx-4">
          <div className="flex-1 m-4">
            <img
              src={HeroImg}
              width="100%"
              height="100%"
              alt="ルンルンバナナ ロゴ"
            />
          </div>
          <div className="flex-1 m-4">
            <div className="w-full h-full flex flex-col justify-center items-center">
              <div className="text-xl">バナナジュース専門店</div>
              <div className="text-4xl">ルンルンバナナ</div>
            </div>
          </div>
        </section>
        <section className="flex justify-center p-4 m-4">
          東京都江東区猿江のバナナジュース専門店です。
          都営新宿線/東京メトロ半蔵門線の住吉駅B1出口より徒歩4分です。
          しっかりと追熟をしたバナナを2本分使った自慢の濃厚なバナナジュースです。
          ミルク味、チョコ味、黒ごま味など様々なフレーバーを用意しています。
        </section>
        <section className="flex flex-col items-center p-4 m-4">
          <h2 className="block text-2xl">こだわり</h2>
          <div>
            フィリピン産の低地栽培バナナを使用した、濃厚な食感ながらもあっさりとした毎日でも飲めるバナナジュースです。バナナ2本分を贅沢に使った満足度の高い一品です。
          </div>
        </section>
        <section className="flex flex-col p-4 m-4">
          <h2 className="text-2xl text-center">メニュー</h2>
          <ul>
            <li>シンプルミルク</li>
            <li>チョコ</li>
            <li>黒ごま</li>
          </ul>
        </section>
        <section className="flex flex-col items-center p-4 mt-4">
          <h2 className="text-2xl">所在地</h2>
          <address>
            <span>東京都江東区猿江1-23-9</span>
            <br />
            <span>パールメゾン 101</span>
          </address>
          <div className="rounded-md shadow">
            <a
              href="https://g.page/runrun_banana"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 md:py-4 md:text-lg md:px-10"
            >
              Googleマップで表示
            </a>
          </div>
        </section>
      </div>
    </main>
  );
}
